import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton, Seo } from 'components';
import Verified from 'assets/icons/verified-results-review.svg';
import { useRouter } from 'apis/history';
import { useQuizData } from 'utils/hooks';
import Header from 'components/Header';
import ResultsProgress from 'components/ResultsProgress';
import { decideWeightDisplayValue } from 'utils/units';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const ResultsReviews: FC = () => {
  const [loading, setLoading] = useState(false);
  const data = useQuizData('resultsReviews');
  const { goToCheckout } = useRouter();

  const nextStep = () => {
    setLoading(true);
    goToCheckout({ discount: false });
  };
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );

  return (
    <>
      <Seo />
      <Outter>
        <Header sticky={false} />
        <ResultsProgress step={5} />
      </Outter>
      <Container>
        <Title dangerouslySetInnerHTML={{ __html: data?.label }} />
        <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
        <ListContainer>
          {data?.list.map((item, index) => {
            const weight = decideWeightDisplayValue(
              item?.kgConvert,
              quiz_answers?.units_of_measure,
            );
            return (
              <ListItem key={index}>
                <ImgContainer>
                  <StyledImg src={item.img} alt="Carnimeat users" />
                </ImgContainer>
                <TextContainer>
                  <ListText>{item.review.replace('${kg}', weight)}</ListText>
                  <BottomContainer>
                    <Name>{item.name}</Name>
                    <Verified />
                  </BottomContainer>
                </TextContainer>
              </ListItem>
            );
          })}
        </ListContainer>
        <ButtonContainer>
          <ContinueButtonStyled onClick={nextStep} loading={loading}>
            {data?.buttonTitle}
          </ContinueButtonStyled>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default ResultsReviews;

const Container = styled.div`
  padding: 2rem 1rem 6rem;
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
    padding: 1rem 1rem 6rem;
  }
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 0.5rem;
  span {
    color: #dc4b4b;
  }
  @media ${tablet} {
    font-size: 1.5rem;
    padding-bottom: 0.375rem;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 1.5rem;
  span {
    font-weight: 600;
  }
`;
const TextContainer = styled.section`
  display: flex;
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    background: #fff;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;
const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const ListItem = styled.section`
  display: flex;
  gap: 1rem;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid #f5f5f5;
  background: #f9f9f9;
`;

const ListText = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Name = styled.p`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const BottomContainer = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ImgContainer = styled.section`
  width: 6.125rem;
`;

const StyledImg = styled(DynamicImage)`
  width: 6.125rem;
  height: 100%;
`;
